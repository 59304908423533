.root {
  background-color: white;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  font-weight: bolder;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid var(--grey-light);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.appTitle {
  color: var(--primary-colour);
  font-weight: bolder;
  margin: 0;
}
